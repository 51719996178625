import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "Orchid Infrastructure Developers",
  address: "Mayfield, Gurgaon",
  client: "Orchid Infrastructure Pvt. Ltd",
  area: "3.5 Acres<br/>6 Lacs Sq. Ft.",
  project:
    "Office Building for Orchid Infrastructure Developers Pvt. Ltd., Gurgaon",
  category: "Commercial | Architecture | Offices",
  status: "Under Municipal Approval",
  backlink: "/projects/commercial/",
};

export default class Projectorchidinfrastruturedevelopers extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Commercial Projects | Orchid Infrastructure Pvt. Ltd."
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="Explore the site maps and project details of an office building and dedicated commercial space commissioned by a top real estate developer."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
