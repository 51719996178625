const data = [
  {
    src:
      "https://archdesignsstudio.com/assets/projects/orchidinfrastructuredevelopers/main/pic1.png",
    text: "1",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects/orchidinfrastructuredevelopers/main/pic2.png",
    text: "2",
  },
];

export default data;
