import React, { useEffect, useState } from "react";
import projectHex from "../../images/BACKGROUND.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./style.scss";
import _ from "lodash";
import { Link } from "gatsby";
export default function ProjectLayout2({ details, projectimages }) {
  let {
    title,
    address,
    client,
    area,
    project,
    description,
    backlink,
    category,
    status,
  } = details;

  if (_.isUndefined(backlink)) {
    backlink = "/projects/";
  }
  const [currentImagePosition, setCurrentPosition] = useState(0);

  useEffect(() => {
    const firstImage = document.querySelectorAll(
      ".projectlayout2__slider-images__img"
    )[0];

    firstImage.classList.add("projectlayout2__slider-images__img--selected");
  }, []);

  function changeCurrentImage(e, data) {
    setCurrentPosition(data);
  }

  function nextImage() {
    console.log("next");
    let currentPosition = currentImagePosition;

    currentPosition++;
    console.log(currentPosition);
    if (currentPosition >= projectimages.length) {
      currentPosition -= projectimages.length;
    }
    setCurrentPosition(currentPosition);
  }

  function previousImage() {
    console.log("back");
    let currentPosition = currentImagePosition;

    currentPosition--;
    if (currentPosition < 0) {
      currentPosition += projectimages.length;
    }
    setCurrentPosition(currentPosition);
  }

  return (
    <div className="projectlayout2">
      <h6 className="projectlayout2__navigation">
        <Link className="projectlayout2__navigation__back" to={backlink}>
          &#8592;
        </Link>
        {category}
      </h6>
      <h1 className="projectlayout2__title">{title}</h1>
      <div
        onContextMenu={(e) => {
          e.preventDefault();
        }}
        className="projectlayout2__image-wrap"
      >
        {/* <div className="projectlayout2__text">
          {projectimages[currentImagePosition].text}
        </div> */}

        <LazyLoadImage
          alt="Project Image"
          className="projectlayout2__image"
          effect="blur"
          src={projectimages[currentImagePosition].src}
        />

        <button
          className="projectlayout2__nextbutton"
          onClick={nextImage}
        ></button>
        <button
          className="projectlayout2__backbutton"
          onClick={previousImage}
        ></button>
        <div className="projectlayout2__slider-images">
          {projectimages.map((img, i) => (
            <LazyLoadImage
              key={i}
              src={img.src}
              alt="Project thumbnail image"
              className={`projectlayout2__slider-images__img ${currentImagePosition === i &&
                "projectlayout2__slider-images__img--selected"
                }`}
              onClick={(e) => {
                changeCurrentImage(e, i);
              }}
            ></LazyLoadImage>
          ))}
        </div>
      </div>

      <div className="projectlayout2__container">
        {(address.length > 0 ||
          client.length > 0 ||
          project.length > 0 ||
          area.length > 0) && (
            <div className="projectlayout2__details-container">
              <img
                className="projectlayout2__details-container__hex"
                src={projectHex}
                alt="project hex element"
              ></img>
              <img
                className="projectlayout2__details-container__hex2"
                src={projectHex}
                alt="project hex element"
              ></img>

              <div className="row">
                {address.length > 0 && (
                  <div className="col col-12 col-lg-6">
                    <h4 className="projectlayout2__details-container__heading-secondary">
                      Address
                    </h4>
                    <p className="projectlayout2__details-container__text">
                      {address}
                    </p>
                  </div>
                )}
                {client.length > 0 && (
                  <div className="col col-12 col-lg-6">
                    <h4 className="projectlayout2__details-container__heading-secondary">
                      Client
                    </h4>
                    <p className="projectlayout2__details-container__text">
                      {client}
                    </p>
                  </div>
                )}
                {area.length > 0 && (
                  <div className="col col-12 col-lg-6">
                    <h4 className="projectlayout2__details-container__heading-secondary">
                      Area
                    </h4>
                    <p
                      className="projectlayout2__details-container__text"
                      dangerouslySetInnerHTML={{ __html: area }}
                    ></p>
                  </div>
                )}
                {project.length > 0 && (
                  <div className="col col-12 col-lg-6">
                    <h4 className="projectlayout2__details-container__heading-secondary">
                      Project
                    </h4>
                    <p
                      className="projectlayout2__details-container__text"
                      dangerouslySetInnerHTML={{ __html: project }}
                    />
                  </div>
                )}
                {status && (
                  <div className="col col-12 col-lg-6">
                    <h4 className="projectlayout2__details-container__heading-secondary">
                      Status
                    </h4>
                    <p className="projectlayout2__details-container__text">
                      {status}
                    </p>
                  </div>
                )}{" "}
              </div>
            </div>
          )}
      </div>
    </div>
  );
}
